export enum IQrFilterType {
  eq = 'eq', //EQUALITY;
  neq = 'neq', //NEGATION;
  gt = 'gt', // GREATER_THAN;
  gte = 'gte', //GREATER_THAN_EQUAL; // mayor o igual
  lt = 'lt', //LESS_THAN;
  lte = 'lte', //LESS_THAN_EQUAL; // menor o igual
  like = 'like', //LIKE
  in = 'in', // IN
  nin = 'nin', // NOT IN
  withinradius = 'withinradius', // DENTRO DE UN RADIO A PARTIR DE UN PUNTO -- NO SE USA POR AHORA
  withinarea = 'withinarea', //  poligono que viende de google maps
  featuresin = 'featuresin',
  conditionsin = 'conditionsin',
  officeid = 'officeid',
  officeId = 'officeId',
  locations = 'locations',
  pricein = 'pricein',
  landingPath = 'landingPath',
  officeName = 'officeName',
  listingsIds = 'listingsIds',
}
