// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import { isClockSkewError } from './isClockSkewError';
/**
 * Get retry decider function
 * @param errorParser Function to load JavaScript error from HTTP response
 */
export var getRetryDecider = function (errorParser) {
  return function (response, error) {
    return __awaiter(void 0, void 0, void 0, function () {
      var errorCode, _a, statusCode;
      var _b;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (!(error !== null && error !== void 0)) return [3 /*break*/, 1];
            _a = error;
            return [3 /*break*/, 3];
          case 1:
            return [4 /*yield*/, errorParser(response)];
          case 2:
            _a = _c.sent();
            _c.label = 3;
          case 3:
            errorCode = ((_b = _a) !== null && _b !== void 0 ? _b : {}).name;
            statusCode = response === null || response === void 0 ? void 0 : response.statusCode;
            return [2 /*return*/, isConnectionError(error) || isThrottlingError(statusCode, errorCode) || isClockSkewError(errorCode) || isServerSideError(statusCode, errorCode)];
        }
      });
    });
  };
};
// reference: https://github.com/aws/aws-sdk-js-v3/blob/ab0e7be36e7e7f8a0c04834357aaad643c7912c3/packages/service-error-classification/src/constants.ts#L22-L37
var THROTTLING_ERROR_CODES = ['BandwidthLimitExceeded', 'EC2ThrottledException', 'LimitExceededException', 'PriorRequestNotComplete', 'ProvisionedThroughputExceededException', 'RequestLimitExceeded', 'RequestThrottled', 'RequestThrottledException', 'SlowDown', 'ThrottledException', 'Throttling', 'ThrottlingException', 'TooManyRequestsException'];
var TIMEOUT_ERROR_CODES = ['TimeoutError', 'RequestTimeout', 'RequestTimeoutException'];
var isThrottlingError = function (statusCode, errorCode) {
  return statusCode === 429 || THROTTLING_ERROR_CODES.includes(errorCode);
};
var isConnectionError = function (error) {
  return (error === null || error === void 0 ? void 0 : error.name) === 'Network error';
};
var isServerSideError = function (statusCode, errorCode) {
  return [500, 502, 503, 504].includes(statusCode) || TIMEOUT_ERROR_CODES.includes(errorCode);
};