// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { Framework } from './types';
import { version } from './version';
import { detectFramework, observeFrameworkChanges } from './detectFramework';
var BASE_USER_AGENT = "aws-amplify";
var PlatformBuilder = /** @class */function () {
  function PlatformBuilder() {
    this.userAgent = "".concat(BASE_USER_AGENT, "/").concat(version);
  }
  Object.defineProperty(PlatformBuilder.prototype, "framework", {
    get: function () {
      return detectFramework();
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PlatformBuilder.prototype, "isReactNative", {
    get: function () {
      return this.framework === Framework.ReactNative || this.framework === Framework.Expo;
    },
    enumerable: false,
    configurable: true
  });
  PlatformBuilder.prototype.observeFrameworkChanges = function (fcn) {
    observeFrameworkChanges(fcn);
  };
  return PlatformBuilder;
}();
export var Platform = new PlatformBuilder();
export var getAmplifyUserAgentObject = function (_a) {
  var _b = _a === void 0 ? {} : _a,
    category = _b.category,
    action = _b.action,
    framework = _b.framework;
  var userAgent = [[BASE_USER_AGENT, version]];
  if (category) {
    userAgent.push([category, action]);
  }
  userAgent.push(['framework', detectFramework()]);
  return userAgent;
};
export var getAmplifyUserAgent = function (customUserAgentDetails) {
  var userAgent = getAmplifyUserAgentObject(customUserAgentDetails);
  var userAgentString = userAgent.map(function (_a) {
    var _b = __read(_a, 2),
      agentKey = _b[0],
      agentValue = _b[1];
    return "".concat(agentKey, "/").concat(agentValue);
  }).join(' ');
  return userAgentString;
};