import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IQrResponse } from '../../core/models/IQrResponse';
import { environment } from '@base/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilsService {
  constructor(
    protected http: HttpClient,
    @Inject('API_URL') private _url: string
  ) {}

  public getParams<P, R>(params: P): Observable<R> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.get<R>(`${environment.apiUrl}${this._url}?${params}`, {
          headers,
        })
      ),
      catchError(this.handleError)
    );
  }

  public getData<R>(endpoint: string): Observable<IQrResponse<R>> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.get<IQrResponse<R>>(
          `${environment.apiUrl}${this._url}/${endpoint}`,
          { headers }
        )
      ),
      catchError(this.handleError)
    );
  }

  public postParams<P, R>(params: P, body: R): Observable<R> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.post<R>(`${environment.apiUrl}${this._url}?${params}`, body, {
          headers,
        })
      ),
      catchError(this.handleError)
    );
  }

  public deleteData<P>(objectId: P): Observable<void> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.delete<void>(
          `${environment.apiUrl}${this._url}/${objectId}`,
          { headers }
        )
      ),
      catchError(this.handleError)
    );
  }

  public updateData<P, R>(objectId: P, body: R): Observable<void> {
    return this.setJwtToken().pipe(
      switchMap(headers =>
        this.http.put<void>(
          `${environment.apiUrl}${this._url}/${objectId}`,
          body,
          {
            headers,
          }
        )
      ),
      catchError(this.handleError)
    );
  }

  private setJwtToken(): Observable<HttpHeaders> {
    return from(Auth.currentSession()).pipe(
      switchMap(session => {
        const token = session.getAccessToken().getJwtToken();
        return new Observable<HttpHeaders>(observer => {
          observer.next(new HttpHeaders({ Authorization: `Bearer ${token}` }));
          observer.complete();
        });
      }),
      catchError(
        () =>
          new Observable<HttpHeaders>(observer => {
            observer.next(new HttpHeaders());
            observer.complete();
          })
      )
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage: string;
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error occurred
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Server-side error: ${error.status} ${error.message}`;
    }
    console.error('Error occurred:', errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
