var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { ConsoleLogger as Logger } from '../Logger';
import { browserOrNode } from '../JS';
import { NonRetryableError } from '../Util';
var logger = new Logger('CognitoCredentials');
var waitForInit = new Promise(function (res, rej) {
  if (!browserOrNode().isBrowser) {
    logger.debug('not in the browser, directly resolved');
    return res();
  }
  var ga = window['gapi'] && window['gapi'].auth2 ? window['gapi'].auth2 : null;
  if (ga) {
    logger.debug('google api already loaded');
    return res();
  } else {
    setTimeout(function () {
      return res();
    }, 2000);
  }
});
var GoogleOAuth = /** @class */function () {
  function GoogleOAuth() {
    this.initialized = false;
    this.refreshGoogleToken = this.refreshGoogleToken.bind(this);
    this._refreshGoogleTokenImpl = this._refreshGoogleTokenImpl.bind(this);
  }
  GoogleOAuth.prototype.refreshGoogleToken = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!!this.initialized) return [3 /*break*/, 2];
            logger.debug('need to wait for the Google SDK loaded');
            return [4 /*yield*/, waitForInit];
          case 1:
            _a.sent();
            this.initialized = true;
            logger.debug('finish waiting');
            _a.label = 2;
          case 2:
            return [2 /*return*/, this._refreshGoogleTokenImpl()];
        }
      });
    });
  };
  GoogleOAuth.prototype._refreshGoogleTokenImpl = function () {
    var ga = null;
    if (browserOrNode().isBrowser) ga = window['gapi'] && window['gapi'].auth2 ? window['gapi'].auth2 : null;
    if (!ga) {
      logger.debug('no gapi auth2 available');
      return Promise.reject('no gapi auth2 available');
    }
    return new Promise(function (res, rej) {
      ga.getAuthInstance().then(function (googleAuth) {
        if (!googleAuth) {
          logger.debug('google Auth undefined');
          rej(new NonRetryableError('google Auth undefined'));
        }
        var googleUser = googleAuth.currentUser.get();
        // refresh the token
        if (googleUser.isSignedIn()) {
          logger.debug('refreshing the google access token');
          googleUser.reloadAuthResponse().then(function (authResponse) {
            var id_token = authResponse.id_token,
              expires_at = authResponse.expires_at;
            res({
              token: id_token,
              expires_at: expires_at
            });
          }).catch(function (err) {
            if (err && err.error === 'network_error') {
              // Not using NonRetryableError so handler will be retried
              rej('Network error reloading google auth response');
            } else {
              rej(new NonRetryableError('Failed to reload google auth response'));
            }
          });
        } else {
          rej(new NonRetryableError('User is not signed in with Google'));
        }
      }).catch(function (err) {
        logger.debug('Failed to refresh google token', err);
        rej(new NonRetryableError('Failed to refresh google token'));
      });
    });
  };
  return GoogleOAuth;
}();
export { GoogleOAuth };